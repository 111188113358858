import { BehaviorSubject, Subject } from 'rxjs';

export abstract class AbstractStoreService {

  protected readonly loading = new BehaviorSubject<boolean>(true);
  public readonly loading$ = this.loading.asObservable();

  protected readonly error = new Subject<any>();
  public readonly error$ = this.error.asObservable();

  constructor() {}
}
