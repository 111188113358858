import { firstValueFrom } from 'rxjs';
import { HttpCommandService } from './../../services/http-command.service';
import { ProductSelectionStoreService } from './../product-selection-store.service';
import { AbstractPollStatusStoreService } from "./abstract-poll-status-store.service";

export class VendonPollSatusStoreService extends AbstractPollStatusStoreService {

  constructor(
    protected selectionService: ProductSelectionStoreService,
    protected commandService: HttpCommandService
  ) {
    super();
  }

  public async poll(){
    const call$ = this.commandService.getVendonStatus( this.transaction_id, this.selectionService.token as string );
    try {
      const response = await firstValueFrom( call$ );
      this.processResult(response);
    } catch (error) {
      this.processError(error);
    }
  }
}
