<ng-container *ngIf="store">
  <div>
    <ng-container *ngTemplateOutlet="loadedMenu; context:{store, selection:store.currentSelection$ | async}">
    </ng-container>
  </div>
</ng-container>

<ng-template #loadedMenu let-store="store" let-selection="selection">
  <ng-container *ngTemplateOutlet="recapMenu; context:{store,product:selection?.product}"></ng-container>
</ng-template>

<ng-template #recapMenu let-store="store" let-product="product">
  <div class="recap-menu" *ngIf="product">
    <div class="single-text-row product-name">{{product.label}}</div>

    <div class="row justify-content-center">
      <div class="col-auto">
        <img class="product-logo" [src]="product.picture | productLogo" [alt]="product.label">
      </div>
    </div>

    <div class="single-text-row font-weight-bold">Price</div>
    <div class="single-text-row font-italic">
      {{(product.price??0) | number : '1.2-2'}} {{product.currency}}
    </div>

    <div class="buttons-container">
      <div class="single-text-row">Make sure you already placed your cup</div>
      <div class="d-flex justify-content-center mt-3 gap-3">
        <button class="btn btn-light btn-product" (click)="brew.emit()">
          START
        </button>
        <button class="btn btn-outline-light btn-product" (click)="cancel()">
          CANCEL
        </button>

      </div>
    </div>
  </div>

</ng-template>
