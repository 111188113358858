import { Injectable } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TouchlessStatus } from '../enums/touchless-status.enum';

@Injectable()
export class MockupInterceptor implements HttpInterceptor {

  useMockup = false;

  constructor() {
    this.useMockup = window.location.href.includes('useMockup=true') || environment.useMockup;
    console.log('MockupInterceptor constructor called');
    console.log( 'ENVIRONMENT', environment )
    console.log( `useMockup: ${this.useMockup}`);
  }

  pollingIterations = 0;
  status_codes = [
    // TouchlessStatus.PAYMENT_IN_PROGRESS,
    // TouchlessStatus.PAYMENT_ACCEPTED,
    TouchlessStatus.DISPENSING,
    TouchlessStatus.DISPENSING,
    TouchlessStatus.DISPENSING,
    TouchlessStatus.SUCCESS
  ];
  menuStatus = 200;
  brewStatus = 200;

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // SKIP THIS INTERCEPTOR IF NOT MOCKUP ACTIVATED
    console.log('use mockup interceptor',this.useMockup);
    if( ! this.useMockup ){
      console.log('skipping mockup interceptor');
      return next.handle(request);
    }
    console.log(`Command interceptor url:${request?.url} command:${request?.body?.command}`)
    if( request.url.includes('/commands') ){
      const cmd = request?.body?.command;
      switch (cmd) {
        case 'menu': // MENU MOCKUP
          return this.menuMockup(request,next);
        case 'brew': // BREW MOCKUP
          return this.brewMockup(request,next);
        case 'create-payment-session': // CREATE SESSION MOCKUP
          return this.error({status: 501, statusText: 'Not yet implemented', error:{message:"Bad request: Input parameters error"}});
        default: // MOCKUP POLLING
          return this.pollMockup(request,next);
      }
    }
    if( request.url.includes('/pov/recommendation') ){
      return this.recommendationMockup(request,next);
    }
    if( request.url.includes('/pov/rating') ){
      return this.ratingnMockup(request,next);
    }
    return next.handle(request);
  }

  menuMockup(request: HttpRequest<any>, next: HttpHandler) : Observable<HttpEvent<any>> {
    switch( this.menuStatus ){
      case 400:return this.error({status: 400, statusText: 'Simulated erorr', error:{message:"Bad request: Input parameters error"}});
      case 401:return this.error({status: 401, statusText: 'Simulated erorr', error:{message:"Unauthorized: Token not valid."}});
      case 412:return this.error({status: 412, statusText: 'Simulated erorr', error:{message:"Precondition Failed: Machine is busy."}});
      case 428:return this.error({status: 428, statusText: 'Simulated erorr', error:{message:"Precondition Required: Token could not be decoded. Error while elaborating the request."}});
      case 500:return this.error({status: 500, statusText: 'Simulated erorr', error:{message:"Internal server error"}});
      case 502:return this.error({status: 502, statusText: 'Simulated erorr', error:{message:"Bad Gateway"}});
      default:
      const url = `assets/json/mockup/${request?.body?.token}`;
      console.log(`Command interceptor redirect it to:${url}`)
      return next.handle(request.clone({method:'get',url}));
    }
  }

  brewMockup(request: HttpRequest<any>, next: HttpHandler) : Observable<HttpEvent<any>>{
    switch( this.brewStatus ){
      case 400:return this.error({status: 400, statusText: 'Simulated erorr', error:{message:"Bad request: Input parameters error"}});
      case 401:return this.error({status: 401, statusText: 'Simulated erorr', error:{message:"Unauthorized: Token not valid."}});
      case 412:return this.error({status: 412, statusText: 'Simulated erorr', error:{message:"Precondition Failed: Machine is busy."}});
      case 428:return this.error({status: 428, statusText: 'Simulated erorr', error:{message:"Precondition Required: Token could not be decoded. Error while elaborating the request."}});
      case 500:return this.error({status: 500, statusText: 'Simulated erorr', error:{message:"Internal server error"}});
      case 502:return this.error({status: 502, statusText: 'Simulated erorr', error:{message:"Bad Gateway"}});
      default:
      const url = `assets/json/mockup/startbrew.json`;
      console.log(`Command interceptor redirect it to:${url}`)
      return next.handle(request.clone({method:'get',url}));
    }
  }

  pollMockup(request: HttpRequest<any>, next: HttpHandler) : Observable<HttpEvent<any>>{
    console.log( `Mockup interceptor polling response number ${this.pollingIterations}` );
    const results = this.status_codes;
    if( this.pollingIterations >= results.length ) {
      this.pollingIterations = 0;
    };
    let status_code = results[results.length-1];
    if( this.pollingIterations < results.length ) {
      status_code = results[this.pollingIterations];
      this.pollingIterations = this.pollingIterations + 1;
    }
    const url = `assets/json/mockup/polling-result-${status_code}.json`;
    console.log(`Command interceptor redirect it to:${url}`);
    return next.handle(request.clone({method:'get',url}));
  }

  recommendationMockup(request: HttpRequest<any>, next: HttpHandler) : Observable<HttpEvent<any>>{
    console.log( `Mockup interceptor recommendation response` );
    const url = `assets/json/mockup/response_suggestion.json`;
    console.log(`Command interceptor redirect it to:${url}`);
    return next.handle(request.clone({method:'get',url}));
  }

  ratingnMockup(request: HttpRequest<any>, next: HttpHandler) : Observable<HttpEvent<any>>{
    console.log( `Mockup interceptor recommendation response` );
    const url = `assets/json/mockup/response_rating.json`;
    console.log(`Command interceptor redirect it to:${url}`);
    return next.handle(request.clone({method:'get',url}));
  }

  error(error:any){
    return throwError( () => new HttpErrorResponse( error) );
  }
}

/**
 * Provider for the interceptor
 */
 export const MockupInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: MockupInterceptor,
  multi: true
};
