import { IdentityStoreService } from 'src/app/modules/app-common/stores/identity-store.service';
import { Injectable } from '@angular/core';
import { HttpApiService } from './http-api.service';
import { PovStoreService } from '../stores/pov-store.service';

@Injectable({
  providedIn: 'root'
})
export class HttpCommandService {

  COMMANDS_PATH = '/commands';

  constructor(
    private api: HttpApiService,
    private pov: PovStoreService,
    private user: IdentityStoreService
  ) {}

  getMenu(token: string) {
    const body = { command: 'menu', token };
    return this.api.post(this.COMMANDS_PATH, body);
  }

  startFrankeDispensing(product_id: string, token: string) {
    const body = { command: 'brew', product_id, token, povId:this.pov.povCode, userId:this.user.userid };
    console.log('franke brew command body:',body);
    return this.api.post(this.COMMANDS_PATH, body);
  }

  startVendonDispensing(credit: number, selection_id: number, token: string) {
    const body = { command: 'brew', credit, selection_id, token, povId:this.pov.povCode, userId:this.user.userid };
    console.log('vendon brew command body:',body);
    return this.api.post(this.COMMANDS_PATH, body);
  }

  startVendonPayment(credit: number, currency: string, return_url:string, selection_id: number, token: string) {
    const body = { command: 'create-payment-session', credit, currency, return_url, selection_id, token };
    return this.api.post(this.COMMANDS_PATH, body);
  }

  getVendonStatus(transaction_id: string, token: string) {
    return this.api.get(`${this.COMMANDS_PATH}?transaction_id=${transaction_id}&token=${token}`);
  }

  getFrankeStatus(transaction_id: string, token: string) {
    return this.api.get(`${this.COMMANDS_PATH}?transaction_id=${transaction_id}&token=${token}`);
  }
}
