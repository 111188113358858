import { Router } from '@angular/router';
import { AbstractAppComponent } from '../AbstractAppComponent';
import { IMachineStore } from '../models/machine.model';
import { MachineFactoryStoreService } from '../stores/machine-factory-store.service';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Output } from '@angular/core';

export class AbstractBeverageSelectionComponent extends AbstractAppComponent {

  public factory: MachineFactoryStoreService;
  public store: IMachineStore;
  protected router: Router;

  private readonly gotoPage = new BehaviorSubject<string>('');
  public readonly gotoPage$ = this.gotoPage.asObservable();

  constructor(
    router: Router,
    factory: MachineFactoryStoreService,

  ) {
    super();
    this.router = router;
    this.factory = factory;
  }

  init(){
    this.register(
      this.factory.currentStore$.subscribe(
        store => {
          this.store = store;
          if( ! store ) return;
          if( store.vendor === 0 ){
            this.initVendonNavigation();
          } else {
            this.initFrankeNavigation();
          }
        }
      )
    );
  }

  private initVendonNavigation(){
    this.register(
      this.store.currentSelection$.subscribe(
        s => {
          if( ! s || ! s.family ){
            this.gotoPage.next('make-a-coffee');
          } else {
            this.gotoPage.next('vendon-product-recap');
          }
        }
      )
    );
  }

  private initFrankeNavigation(){
    this.register(
      this.store.currentSelection$.subscribe(
        s => {
          if( ! s || ! s.family ){
            this.gotoPage.next('make-a-coffee');
          } else if( ! s.cupsize ){
            this.gotoPage.next('cupsize-menu');
          } else {
            this.gotoPage.next('franke-product-recap');
          }
        }
      )
    );
  }

  selectFamily(code:string){
    this.store.selectFamily(code);
    this.store.selectCupsize(null);
    this.store.selectProduct(null);
  }

  selectCupsize(code:string){
    this.store.selectCupsize(code);
    this.store.selectProduct(null);
  }

  selectProduct(code:string){
    this.store.selectProduct(code);
  }

}
