import { AfterViewInit, Component, OnInit, OnDestroy, Input, HostListener } from '@angular/core';
import {Html5Qrcode, Html5QrcodeSupportedFormats} from "html5-qrcode"

var scanned = false;

@Component({
  selector: 'pov-scanner',
  templateUrl: './scanner.component.html',
  styleUrls: ['./scanner.component.scss']
})
export class ScannerComponent implements OnInit, AfterViewInit, OnDestroy {

  scanner: Html5Qrcode;

  @Input()
  ratio: number = 0;

  constructor(
  ) {
    scanned = false;
  }

  @HostListener('window:resize', ['$event'])
	onResize(event:any) {
    this.setRatio( event.target.innerWidth, event.target.innerHeight );
	}

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.setRatio( window.innerWidth, window.innerHeight );
    this.startScan();
  }

  setRatio(w:number,h:number){
		this.ratio = h/w;
    console.log( 'scanner ratio:',this.ratio );
  }

  ngOnDestroy(): void {
    this.stop();
  }

  async startScan(){
    this.scanner = new Html5Qrcode("scanner", { formatsToSupport: [ Html5QrcodeSupportedFormats.QR_CODE ], verbose: false } );
      try{
        this.scanner.start(
        { facingMode: "environment" },
          {
            fps: 30,    // Optional, frame per seconds for qr code scanning
            qrbox: { width: 250, height: 250 },  // Optional, if you want bounded box UI
            aspectRatio: this.ratio
          },
          this.success,
          this.failure
        );
      }
      catch(err){
        console.log( 'starting camera with id' );
        const devices = await ( Html5Qrcode.getCameras() );
        var cameraId = devices[0].id;
        if (devices && devices.length) {
        this.scanner.start(
          cameraId,
          {
            fps: 30,    // Optional, frame per seconds for qr code scanning
            qrbox: { width: 250, height: 250 },  // Optional, if you want bounded box UI
            aspectRatio: this.ratio
          },
          this.success,
          this.failure
        );
      }
    }
  }

  success(decodedText:any, decodedResult:any){
    setTimeout(() => {
      if( scanned )
        return;
      scanned = true;
      console.log(decodedText, decodedResult);
      window.location = decodedText;
    }, 100);
  }

  failure(errorMessage:any){
  }

  start(){
    this.startScan();
  }

  stop(){
    if( this.scanner ){
      this.scanner.stop();
      this.scanner.clear();
    }
  }

  close(){
    window.history.back();
  }
}
