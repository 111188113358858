import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ManifestService {

  private manifestSelectors: string[] = ["link[rel*='manifest']"];

  constructor(@Inject(DOCUMENT) private document: any) {}

  setBase(folder:string){
    this.listManifests('manifests BEFORE CHANGE:');
    this.getManifests().forEach( e => {
      e.href = e.href.replace( /\/assets\/manifests\/.*./,`/assets/manifests/${folder}.webmanifest`);
    });
    this.listManifests('manifests AFTER CHANGE:');
  }

 private getManifests(): HTMLLinkElement[] {
    const links: NodeListOf<HTMLLinkElement>[] = this.manifestSelectors.map( s => this.document.querySelectorAll(s) )
    const result: HTMLLinkElement[] = [];
    links.forEach( f => { f.forEach( e => { result.push(e) }) });
    return result;
  }

  private listManifests(msg:string = ''): void {
    this.getManifests().forEach( e => {
      console.log(msg,e.href);
    });
  }
}
