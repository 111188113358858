import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { BrandService } from '../../../services/brand.service';
import { DialogsService } from '../../../services/dialogs.service';
import { MachineStatusService } from '../../../services/machine-status.service';
import { MachineFactoryStoreService } from '../../../stores/machine-factory-store.service';
import { AbstractBeverageSelectionComponent } from '../../abstract-beverage-selection.component';

@Component({
  selector: 'app-franke-product-recap',
  templateUrl: './product-recap.component.html',
  styleUrls: ['./product-recap.component.scss']
})
export class FrankeProductRecapComponent extends AbstractBeverageSelectionComponent implements OnInit {

  @Output()
  brew = new EventEmitter();

  constructor(
    public brandService: BrandService,
    router: Router,
    factory: MachineFactoryStoreService
  ) {
    super(router,factory);
  }

  ngOnInit(): void {
    console.log ('init FrankeProductRecapComponent');
    super.init();
  }


  cancel(){
    this.store.selectProduct(null);
  }
}
