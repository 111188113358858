import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FaviconService {

  private faviconsSelectors: string[] = ["link[rel*='icon']","link[rel*='apple-touch-icon","link[rel*='shortcut icon"];

  constructor(@Inject(DOCUMENT) private document: any) {}

  setBase(folder:string){
    //this.listFavicons('FAVICONS BEFORE CHANGE:');
    this.getFavicons().forEach( e => {
      e.href = e.href.replace( /\/assets\/favicons\/*\//,`/assets/favicons/${folder}/`);
    });
    //this.listFavicons('FAVICONS AFTER CHANGE:');
  }

 private getFavicons(): HTMLLinkElement[] {
    const links: NodeListOf<HTMLLinkElement>[] = this.faviconsSelectors.map( s => this.document.querySelectorAll(s) )
    const result: HTMLLinkElement[] = [];
    links.forEach( f => { f.forEach( e => { result.push(e) }) });
    return result;
  }

  private listFavicons(msg:string = ''): void {
    this.getFavicons().forEach( e => {
      console.log(msg,e.href);
    });
  }
}
