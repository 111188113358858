import { LocalStorageService } from './../services/local-storage.service';
import { Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class IdentityStoreService {

  lsprop: string = 'userid';
  userid: string;

  constructor(
    storage: LocalStorageService
  ) {
    let userid = storage.get(this.lsprop);
    if( ! userid ){
      userid = uuidv4();
      storage.set(this.lsprop,userid);
    }
    this.userid = userid;
    console.log(this.userid );
  }
}
