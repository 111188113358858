import { BehaviorSubject, Observable } from 'rxjs';
import { MenuItem } from '../models/machine.model';
import { AbstractStoreService } from './abstract-store.service';
import { CoffeMenuNames } from './machine-franke-store.service';

export abstract class AbstractMachineStoreService extends AbstractStoreService {

  public machine_id: string;
  public vendor: number;
  public brand: string;

  families: any[];
  products: any[];

  protected readonly currentSelection = new BehaviorSubject<any>(null);
  public readonly currentSelection$ = this.currentSelection.asObservable();

  public statusChange$: Observable<any>;

  constructor() {
    super();
  }

  getCurrentSelection(){
    return this.currentSelection.getValue();
  }

  back() {
    const _current: any = { ...this.currentSelection.getValue() };
    if (_current.cupsize) {
      _current.cupsize = null;
      if (_current.family.cupsizes.length === 1) {
        _current.family = null;
      }
    } else if (_current.family) {
      _current.family = null;
    }
    _current.product = null;
    _current.filters = null;
    _current.filter = null;
    this.currentSelection.next(_current);
  }

  selectFamily(code: string) {
    const _current: any = { ...this.currentSelection.getValue() };
    if (code === null) {
      _current.family = null;
      _current.cupsize = null;
      _current.product = null;
      _current.filters = null;
      _current.filter = null;
    } else {
      _current.family = this.families.find((f) => f.code === code);
      if (_current.family.cupsizes.length === 1) {
        _current.cupsize = _current.family.cupsizes[0];
        _current.product = _current.cupsize.products[0];
        _current.filter = { ..._current.product.filterSelection };
        this.createFilters(_current);
      }
    }
    console.log( _current );
    this.currentSelection.next(_current);
  }

  selectCupsize(code: string) {
    const _current: any = { ...this.currentSelection.getValue() };
    if (code === null) {
      _current.cupsize = null;
      _current.product = null;
      _current.filter = null;
    } else {
      _current.cupsize = _current.family.cupsizes.find(
        (f: any) => f.code === code
      );
      _current.product = _current.cupsize.products[0];
      _current.filter = { ..._current.product.filterSelection };
      this.createFilters(_current);
    }
    this.currentSelection.next(_current);
  }

  selectProduct(code: string) {
    const _current: any = { ...this.currentSelection.getValue() };
    if (code === null) {
      _current.product = null;
      _current.filter = {};
    } else {
      _current.product = _current.cupsizes.products.find(
        (f: any) => f.code === code
      );
      _current.filter = { ..._current.product.filterSelection };
    }
    this.currentSelection.next(_current);
  }

  selectProductByCode(code: string) {
    const _current: any = { ...this.currentSelection.getValue() };
    _current.product = this.products.find(
      (f: any) => f.product_id === code
    );
    _current.filter = { ..._current.product.filterSelection };
    this.currentSelection.next(_current);
  }

  selectProductFeature(code: string, value: string) {
    const _current: any = this.currentSelection.getValue();
    const _products = _current.cupsize.products;
    const _filter: any = { ..._current.filter };
    _filter[code] = value;
    const selection = _products.filter((p: any) =>
      this.filterByCurrentFilter(p, _filter)
    );
    if (selection && selection.length === 1) {
      console.log('SINGLE PRODUCT SELECTED');
      _current.product = selection[0];
      console.log(_current.product);
    } else if (!selection || selection.length === 0) {
      console.log('NO PRODUCT SELECTED');
      _current.product = null;
    } else {
      console.log('MULTIPLE PRODUCT SELECTED');
      _current.product = null;
    }
    _current.filter = _filter;
    this.currentSelection.next(_current);
  }

  filterByCurrentFilter(product: any, filter: any) {
    const filterSelection = product.filterSelection;
    for (const key in filterSelection) {
      if (filterSelection[key] !== filter[key]) {
        return false;
      }
    }
    return true;
  }

  createFilters(current?: any) {
    let _filters: any[] = [];
    const _current: any = current ?? { ...this.currentSelection.getValue() };
    const products = _current.cupsize.products;

    for (const product of products) {
      for (const code in product.filterSelection) {
        const value = product.filterSelection[code];
        if (
          [CoffeMenuNames.beverageFamily, CoffeMenuNames.cupSize].includes(
            code
          ) ||
          value === null
        )
          continue;
        let options = _filters.find((o) => o.code === code);
        if (!options) _filters.push({ code, options: [] });
        options = _filters.find((o) => o.code == code);
        let option = options.options.find((o: any) => o.code === value);
        if (!option)
          options.options.push({
            code: value,
            available: product.available === true,
          });
        option = _filters.find((o) => o.code == code);
        option.available = option.available || product.available;
      }
    }
    _filters = _filters
      .filter(
        (o) => _filters.find((m) => m.code === o.code)?.options?.length > 0
      )
      .sort((a, b) => {
        return -b.code.localeCompare(a.code);
      });
    _filters.forEach((o) => {
      o.options = o.options.sort((a: any, b: any) => {
        const _a = a.code.endsWith('No') ? '_' : a.code;
        const _b = b.code.endsWith('No') ? '_' : b.code;
        return _a.localeCompare(_b);
      });
    });

    current.filters = _filters;
  }

  createOption(
    code: string,
    type: string,
    label: string,
    picture: string,
    available: boolean = true
  ): MenuItem {
    return { code, type, label, picture, available };
  }
}
