import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'productLogo'
})
export class ProductLogoPipe implements PipeTransform {

  imageRefMapping = {
    'pg_americano':             '06_Milano_Americano.png',
    'pg_cafe latte':            '13_Milano_Cafe-au-lait.png',
    'pg_cappuccino':            '07_Milano_Cappucino.png',
    'pg_chocolate':             '09_Milano_Dark-chocolate.png',
    'pg_double espresso':       '03_Milano_double-espresso.png',
    'pg_espresso':              '02_Milano_Espresso.png',
    'pg_espresso macchiato':    '08_Milano_Latte_macchiato.png',
    'pg_flat white':            '14_Milano_Flat-white.png',
    'pg_flavoured cappuccino':  '07_Milano_Cappucino.png',
    'pg_flavoured coffee':      '04_Milano-lungo.png',
    'pg_flavoured latte':       '08_Milano_Latte_macchiato.png',
    'pg_hot milk':              '12_Milano_Babycchino.png',
    'pg_hot water':             '17_Milano_boiled_water.png',
    'pg_latte macchiato':       '08_Milano_Latte_macchiato.png',
    'pg_lungo':                 '04_Milano-lungo.png',
    'pg_mocha':                 '11_Milano_Mochaccino.png',
    'pg_not assigned':          '04_Milano-lungo.png',
    'pg_ristretto':             '01_Milano_ristretto.png',
    'pg_tea mixes':             '15_Milano_Black-tea.png',
    'pg_white coffee':          '05_Milano_Cortado.png'
  } as any;

  transform(value: string): string {
    if(value?.startsWith('http://') || value?.startsWith('https://'))
      return value + "?ngsw-bypass=true";
    const base = '/assets/images/menu/vendon/';
    const mappedString = this.imageRefMapping[value] ?? '04_Milano-lungo.png';
    return `${base}${mappedString}`;
  }

}
