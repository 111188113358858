import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { FaviconService } from '../services/favicon.service';
import { ManifestService } from './../services/manifest.service';

@Injectable({
  providedIn: 'root'
})
export class PovStoreService {

  readonly lovePovName = 'love';
  readonly newsPovName = 'news';
  readonly tastePovName = 'taste';

  public povName:string;
  public povCode:number;

  constructor(
    private router:Router,
    private title: Title,
    private favicon: FaviconService,
    private manifest: ManifestService
  ) {
  }

  init(){
    const path = location.pathname;
    let name:string = '';
    let code:number = 0;
    let title:string = '';
    if( this.isTastePov ){
      title = 'Taste personalization ';
      name = 'taste';
      code = 1;

    } else if( this.isLovePov() ){
      title = 'Coffee with love ';
      name = 'love';
      code = 3;
    } else if( this.isNewsPov() ) {
      title = 'Customer personalization ';
      name = 'news';
      code = 2;
    }
    this.title.setTitle(`Nestlé - ${title}POV`);
    this.manifest.setBase( name );
    this.favicon.setBase( name );
    this.forceLocation();
  }

  forceLocation(){
    // console.log( 'FORCE LOCATION FROM BOOKMARK', environment.forceLocationAtLoading );
    // if( ! environment.forceLocationAtLoading  ){
    //   return;
    // }
    const path = location.pathname;
    if( this.isLovePov() ){
      console.log( 'I am love pov');
      // TODO FORCE ENTRY POINTS FOR SENDER OR RECEIVER

    } else if( this.isNewsPov() ){
      console.log( 'I am news pov');
      // TODO FORCE ENTRY POINTS FOR SENDER OR RECEIVER

    } else if( this.isTastePov() ){
      console.log( 'I am taste pov');
      if( ! path.endsWith('/ps') && ! path.endsWith('/home') &&  ! path.endsWith('/rating-good')  ){
        this.router.navigate(['/','taste','home']);
      }
    }
  }

  isLovePov(){
    return this.isPov( this.lovePovName );
  }

  isNewsPov(){
    return this.isPov( this.newsPovName );
  }

  isTastePov(){
    return this.isPov( this.tastePovName );
  }

  isPov(name:string){
    const path = location.pathname;
    const host = location.hostname;
    console.log('host:',host);
    console.log('path:',path);
    return host.startsWith(`${name}.`);// || path.startsWith('/`${name}/');
  }
}
