export enum TouchlessStatus {
  SUCCESS = 1,
  PAYMENT_ACCEPTED = 2,
  PAYMENT_IN_PROGRESS = 3,
DISPENSING = 5,

BUSY = 10,
FAILED = 11,
FAILED_TIMEOUT = 12,
CANCELED = 14,
MACHINE_OFFLINE = 15,
WRONG_REQUEST = 16,
UNKNOWN_ERROR = 17,
COMMUNICATION_ERROR = 18,
INTERNAL_ERROR = 20,
  PAYMENT_ERROR = 21,
  HMAC_KEY_NOT_VALID = 22,
  ABORTED_TRANSACTION = 23
}
