import { ProductSelectionStoreService } from './product-selection-store.service';
import { HttpCommandService } from './../services/http-command.service';
import { FrankePollSatusStoreService } from './poll-status/franke-poll-status-store.service';
import { IMachineStore, Machine } from './../models/machine.model';
import { Injectable } from '@angular/core';
import { AbstractMachineStoreService } from './machine-abstract-store.service';
import { firstValueFrom, Observable } from 'rxjs';

export const CoffeMenuNames = {
  recap: 'recap',
  beverageFamily:'beverageFamily',
  cupSize:'cupSize',
}

@Injectable({
  providedIn: 'root'
})
export class MachineFrankeStoreService extends AbstractMachineStoreService implements IMachineStore {

  defaults = {
    blendType : "CustomBlend1",
    extraShotEspresso : "ExtraShotEspressoNo",
    flavorType : "FlavorTypeNo"
  }

  result$: Observable<any>;

  pollStore: FrankePollSatusStoreService;

  private static instance: MachineFrankeStoreService;

  public static getInstance(
    productService: ProductSelectionStoreService,
    commandsService: HttpCommandService,
    machine: Machine
  ):MachineFrankeStoreService{
      if( ! MachineFrankeStoreService.instance )
      MachineFrankeStoreService.instance = new MachineFrankeStoreService(productService,commandsService,machine);
      return MachineFrankeStoreService.instance;
  }

  private constructor(
    private productService: ProductSelectionStoreService,
    private commandsService: HttpCommandService,
    private machine: Machine
  ) {
    super();
    machine.data.forEach( f => {
      f.children.forEach( (c: any) => {
        c.product_id = c.productId;
        delete c.productId;
      });
    });
    this.pollStore = new FrankePollSatusStoreService(productService,commandsService);
    this.machine_id = machine.machine_id;
    this.vendor = machine.vendor;
    this.brand = machine.brand;
    this.families = machine.data.map( f => { f.code = f.id; return f } );
    this.rollupMenu( machine.data );
  }

  private rollupMenu(data:any){
    const products = [] as any[];
    const families = data.map( (i:any) => { return i } );
    families.forEach( (family:any) => {
      const cupsizes: any[]  = [];
      family.children.forEach( (product:any) => {
        const cupsizeCode = product.filterSelection.cupSize;
        let cupsize = cupsizes.find( i => i.code === cupsizeCode );
        if( ! cupsize ){
          cupsize = { code: cupsizeCode, products: [] };
          cupsizes.push( cupsize );
        }
        cupsize.products.push(product);
        products.push(product);
      });
      delete family.children;
      family.cupsizes = cupsizes;
    } );
    this.products = products;
    this.families = families;
  }

  public async startErogation(){
    const product_id = this.currentSelection.getValue()?.product.product_id;
    if( ! product_id )
      return;
    console.log(`Start erogation of ${product_id}`);
    try {
      const call = this.commandsService.startFrankeDispensing(product_id, this.productService.token);
      const results = await firstValueFrom( call );
      this.checkResult(results);
    } catch (err) {
      console.log(`Error in start erogation of ${product_id}`,( err as any )?.error);
      this.error.next(err);
    }
  }

  checkResult(result: any) {
    const product_id = this.currentSelection.getValue()?.product_id;
    const product = this.products.find( p => p.product_id === product_id );
    this.pollStore.check(result,product);
  }

  resetMachineMenu(){
    // TODO VERIFICARE STA ROBBBA !
    // this.machineMenus = [{...(this.machineMenus as any )[CoffeMenuNames.beverageFamily]}];
  }

  startPolling(): void {

  }

  stopPolling(){
    this.pollStore.cancel();
  }

}
