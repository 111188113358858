const stage = 'stag';

export const environment = {
  production: false,
  useMockup: false,
  version: 'unknown',
  internalUrl: 'unknown',
  families_names_to_hide: ['Custom Beverage','Custom Beverage '],
  families_enabled_to_suggestion:['Espresso 6','Espresso'],
  families_enabled_to_print:['Espresso 6'],
  stage: stage,
  selfUrl: `https://www.pov.${stage}.touchless.nestleprofessional.niotp.com`,
  apiRootUrl: `https://api.pov.${stage}.touchless.nestleprofessional.niotp.com/command`,
  povRootUrl: `https://api.pov.${stage}.touchless.nestleprofessional.niotp.com/pov`,
  forceLocationAtLoading: true
};


