import { InitialPipe } from './pipes/initial-pipe';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import Swal from 'sweetalert2';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';

import { PovChoiceComponent } from './components/pov-choice/pov-choice.component';
import { RouterModule } from '@angular/router';
import { FrankeMenuIconPipe } from './pipes/franke-menu-icon';
import { FrankeMenuLabelPipe } from './pipes/franke-menu-label';
import { FrankeMenuProductLabelPipe } from './pipes/franke-menu-product-label';
import { ProductLogoPipe } from './pipes/product-logo.pipe';
import { MockupInterceptorProvider } from './interceptors/mockup.interceptor';
import { ErrorInterceptorProvider } from './interceptors/error.interceptor';
import { HeaderComponent } from './components/header/header.component';

import { BeverageMenuComponent } from './components/beverage-menu/beverage-menu.component';
import { CupsizeMenuComponent } from './components/franke/cupsize-menu/cupsize-menu.component';
import { ProductCustomizerComponent } from './components/franke/product-customizer/product-customizer.component';
import { FrankeProductRecapComponent } from './components/franke/product-recap/product-recap.component';
import { VendonProductRecapComponent } from './components/vendon/product-recap/product-recap.component';
import { ErogationComponent } from './components/erogation/erogation.component';
import { AngularMaterialModule } from 'src/app/angular-material.module';
import { LoaderComponent } from './components/loader/loader.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ScannerComponent } from './components/scanner/scanner.component';

@NgModule({
  declarations: [
    PovChoiceComponent,
    BeverageMenuComponent,
    CupsizeMenuComponent,
    FrankeProductRecapComponent,
    VendonProductRecapComponent,
    ProductLogoPipe,
    FrankeMenuIconPipe,
    FrankeMenuLabelPipe,
    FrankeMenuProductLabelPipe,
    InitialPipe,
    HeaderComponent,
    ProductCustomizerComponent,
    ErogationComponent,
    LoaderComponent,
    ScannerComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AngularMaterialModule,
    RouterModule,
    HttpClientModule,
    SweetAlert2Module.forRoot({ provideSwal }),
    NgxSpinnerModule.forRoot({ type: 'ball-spin-clockwise' })
  ],
  providers:[
    MockupInterceptorProvider,
    ErrorInterceptorProvider
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    PovChoiceComponent,
    BeverageMenuComponent,
    CupsizeMenuComponent,
    FrankeProductRecapComponent,
    VendonProductRecapComponent,
    ProductLogoPipe,
    FrankeMenuIconPipe,
    FrankeMenuLabelPipe,
    FrankeMenuProductLabelPipe,
    InitialPipe,
    HeaderComponent,
    ProductCustomizerComponent,
    ErogationComponent,
    LoaderComponent,
    ScannerComponent
  ]
})
export class AppCommonModule { }

export async function provideSwal() {
  return Swal.mixin({
    position: 'center',
    padding: '1em',
    buttonsStyling: false,
    allowOutsideClick: false,
    customClass: {
      popup: 'animated fadeIn fast bg-popup-custom',
      icon: 'bg-header-margin', //TODO REMOVED AFTER MIGRATION
      actions: 'z-index-0',
      confirmButton: 'btn btn-outline-light btn-no-focus',
      cancelButton: 'btn btn-outline-light btn-no-focus',
      title: 'font-size-22 font-weight-bold text-white',
      htmlContainer: 'font-size-16 text-white bg-header-margin',
    },
    confirmButtonText: 'CONFIRM',
    cancelButtonText: 'CANCEL',
    //animation: false, //TODO REMOVED AFTER MIGRATION
  });
}




// NgbModule,
// NgHttpLoaderModule.forRoot(),
// BrowserAnimationsModule
