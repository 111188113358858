import { CommonModule } from '@angular/common';
import {CarouselModule} from 'primeng/carousel';
import { NgModule } from '@angular/core';

const primengModules = [
  CarouselModule
];

@NgModule({
  imports: [
    CommonModule,
    ...primengModules
  ],
  exports: [
    primengModules
  ],
})
export class PrimengModule { }
