import { ScannerComponent } from './modules/app-common/components/scanner/scanner.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PovChoiceComponent } from './modules/app-common/components/pov-choice/pov-choice.component';

import { BeverageMenuComponent } from './modules/app-common/components/beverage-menu/beverage-menu.component';
import { CupsizeMenuComponent } from './modules/app-common/components/franke/cupsize-menu/cupsize-menu.component';
import { FrankeProductRecapComponent } from './modules/app-common/components/franke/product-recap/product-recap.component';
import { VendonProductRecapComponent } from './modules/app-common/components/vendon/product-recap/product-recap.component';

const routes: Routes = [
  {
    path: 'coffee-with-love',
    loadChildren: () =>
      import('./modules/coffee-with-love/coffee-with-love.module').then(
        (m) => m.CoffeeWithLoveModule
      ),
  },
  {
    path: 'taste-personalization',
    loadChildren: () =>
      import(
        './modules/taste-personalization/taste-personalization.module'
      ).then((m) => m.TastePersonalizationModule),
  },
  {
    path: 'customer-personalization',
    loadChildren: () =>
      import(
        './modules/customer-personalization/customer-personalization.module'
      ).then((m) => m.CustomerPersonalizationModule),
  },
  {
    path: 'love',
    loadChildren: () =>
      import('./modules/coffee-with-love/coffee-with-love.module').then(
        (m) => m.CoffeeWithLoveModule
      ),
  },
  {
    path: 'taste',
    loadChildren: () =>
      import(
        './modules/taste-personalization/taste-personalization.module'
      ).then((m) => m.TastePersonalizationModule),
  },
  {
    path: 'news',
    loadChildren: () =>
      import(
        './modules/customer-personalization/customer-personalization.module'
      ).then((m) => m.CustomerPersonalizationModule),
  },
  {
    path: 'scanner',
    component: ScannerComponent
  },  {
    path: 'make-a-coffee',
    component: BeverageMenuComponent
  },
  {
    path: 'cupsize-menu',
    component: CupsizeMenuComponent
  },
  {
    path: 'franke-product-recap',
    component: FrankeProductRecapComponent
  },
  {
    path: 'vendon-product-recap',
    component: VendonProductRecapComponent
  },
  {
    path: '',
    component: PovChoiceComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
