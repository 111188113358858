import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AbstractAppComponent } from '../../AbstractAppComponent';

@Component({
  selector: 'app-pov-choice',
  templateUrl: './pov-choice.component.html',
  styleUrls: ['./pov-choice.component.scss']
})
export class PovChoiceComponent extends AbstractAppComponent implements OnInit {

  query: any;

  constructor(private route: ActivatedRoute) {
    super();
    this.query = {};

  }
  ngOnInit(): void {
    this.register(
      this.route.queryParams.subscribe( params => {
        this.query = params;
        this.clearSubscriptions();
      })
    );
  }
}
