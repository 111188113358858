import { MachineVendor } from '../enums/machine-vendor.enum';
import { Injectable } from '@angular/core';
import { Product } from '../models/product-selection.model';

@Injectable({
  providedIn: 'root'
})
export class ProductSelectionStoreService {

  product: Product;
  vendor: MachineVendor = MachineVendor.Franke;
  token: string;
  machine_id: any;

  constructor() { }
}
