<ng-container *ngIf="store">
  <div class="color-scheme-1" [class.device-vendon]="store.vendor === 0">
    <ng-container *ngTemplateOutlet="currentChoice; context:{store}"></ng-container>
    <ng-container *ngTemplateOutlet="loadedMenu; context:{store}"></ng-container>
  </div>
</ng-container>

<ng-template #currentChoice let-store="store" let-choice="choice">
  <!-- <div class="d-flex flex-column justify-content-center align-items-center color-scheme-1 position-sticky sticky-top">
    <h1>
      Welcome
    </h1>
  </div> -->
</ng-template>

<ng-template #loadedMenu let-store="store">
  <ng-container *ngTemplateOutlet="beverageFamilyMenu; context:{store}"></ng-container>
</ng-template>

<ng-template #beverageFamilyMenu let-store="store">
  <div class="d-flex flex-wrap justify-content-center">
    <ng-container *ngFor="let option of store.families">
      <ng-container *ngIf="! families_names_to_hide.includes(option.name)">
        <button mat-flat-button [disableRipple]="true" class="d-flex align-items-start justify-content-around"
          (click)="selectFamily(option.code)" [disabled]="option.available===false"
          [class.disabled]="option.available === false">
          <div class="d-flex flex-column justify-content-start align-items-center">
            <div class="product-container">
              <img class="product-logo" [src]="option.picture | productLogo" [alt]="option.name">
            </div>
            <div class="product-name">{{option.name}}</div>
          </div>
        </button>
      </ng-container>
    </ng-container>
  </div>
</ng-template>
