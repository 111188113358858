import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BrandService } from '../../../services/brand.service';
import { MachineFactoryStoreService } from '../../../stores/machine-factory-store.service';
import { AbstractBeverageSelectionComponent } from '../../abstract-beverage-selection.component';

@Component({
  selector: 'app-cupsize-menu',
  templateUrl: './cupsize-menu.component.html',
  styleUrls: ['./cupsize-menu.component.scss']
})
export class CupsizeMenuComponent extends AbstractBeverageSelectionComponent implements OnInit {

  constructor(
    public brandService: BrandService,
    router: Router,
    factory: MachineFactoryStoreService,
  ) {
    super(router,factory);
  }

  ngOnInit(): void {
    console.log ('init CupsizeMenuComponent');
    super.init();
  }

  select(code:string){
    this.store.selectCupsize(code);
  }
}
