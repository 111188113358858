import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'frankeIcon'
})
export class FrankeMenuIconPipe implements PipeTransform {

  transform(value: string, brand?:string, type?:string, dimension?:string): string {
    const base = '/assets/images/menu/franke';
    brand = brand ?? 'DEF';
    if( type === 'product' ){
      const dim = dimension ?? '139x125';
      const url =  `${base}/${type}/${dim}/${value}_${dim}.png`
      return url;
    }
    if( type === 'button' ){
      const url =  `${base}/${brand}/${type}/${value}.svg`
      return url;
    }
    if( type === 'cupSize' ){
      const url =  `${base}/${brand}/${type}/${value}.png`
      return url;
    }
    if( type ){
      const url = `${base}/${brand}/${type}/${value}.svg`
      return url;
    }

    if(value?.startsWith('http://') || value?.startsWith('https://'))
      return `${value}?ngsw-bypass=true`;

    // non ha molto senso !
    return `${base}/04_Milano-lungo.png`;
  }

}
