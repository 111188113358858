import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { IMachineStore, Machine } from '../models/machine.model';
import { HttpCommandService } from '../services/http-command.service';
import { AbstractMachineStoreService } from './machine-abstract-store.service';
import { VendonPollSatusStoreService } from './poll-status/vendon-poll-status-store.service';
import { ProductSelectionStoreService } from './product-selection-store.service';

@Injectable({
  providedIn: 'root'
})
export class MachineVendonStoreService extends AbstractMachineStoreService implements IMachineStore {

  pollStore: VendonPollSatusStoreService;

  private static instance: MachineVendonStoreService;

  public static getInstance(
    productService: ProductSelectionStoreService,
    commandsService: HttpCommandService,
    machine: Machine
  ):MachineVendonStoreService{
      if( ! MachineVendonStoreService.instance )
      MachineVendonStoreService.instance = new MachineVendonStoreService(productService,commandsService,machine);
      return MachineVendonStoreService.instance;
  }

  private constructor(
    private productService: ProductSelectionStoreService,
    private commandsService: HttpCommandService,
    private machine: Machine
  ) {
    super();
    this.pollStore = new VendonPollSatusStoreService(productService,commandsService);
    this.machine_id = machine.machine_id;
    this.vendor = machine.vendor;
    this.brand = machine.brand;
    this.rollupMenu( machine.data );
  }

  private rollupMenu(data:any){
    const products = data.map( (i:any) => { return i } );
    const families = data.map( (i:any) => { return i } );
    families.forEach( (f:any) => {
      const cupsizes = [{ code:'default', products:[{...f}]}];
      f.cupsizes = cupsizes;
    });
    this.products = products;
    this.families = families;
  }

  public async startErogation(){
    const product = this.currentSelection.getValue()?.product;
    if( ! product )
      return;
    const position = product.position;
    const credit = product.price <= 0 ? 1 : product.price;
    console.log(`Start erogation of product in position ${position}, credit: ${credit}`, product);
    try {
      const call = this.commandsService.startVendonDispensing(credit, position, this.productService.token);
      const result = await firstValueFrom( call );
      // this.checkResult(result);
    } catch (err) {
      console.log(`Error in start erogation of ${position}, credit: ${credit}`,(err as any)?.error);
      this.error.next(err);
    }
  }

  startPolling(): void {

  }

  stopPolling(){
    this.pollStore.cancel();
  }
}
