import { DialogsService } from './../services/dialogs.service';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HTTP_INTERCEPTORS } from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  static readonly DEFAULT_ERROR_MESSAGE: string = 'Something went wrong, please try again';
  static readonly UNAUTHORIZED_ERROR_MESSAGE: string = 'Unauthorized: access to this resource is denied';
  static readonly FORBIDDEN_ERROR_MESSAGE: string = 'You don\'t have permission to perform this action';

  constructor(
    private dialog: DialogsService,
    private router: Router,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    //console.log('Error interceptor');
    return next.handle(request).pipe( catchError( err => {
      console.log('Error interceptor catch an error',err);
      // skip for polling alternative is  !( request.body?.command )
      if( request.method === 'GET' ){
        return throwError( () => err );
      } else {
        this.logError(request,err);
        const title = 'Error'
        const message = err?.error?.message ?? ErrorInterceptor.DEFAULT_ERROR_MESSAGE;

        this.dialog.showError(title, message).then(
          (r) => { if(r) this.navigateFailurePage() }
        )

        return throwError( () => err);
      }
    }));
  }

  navigateFailurePage(){
    this.router.navigate(['error']);
  }

  logError(request:HttpRequest<unknown>,err:any){
    console.log('============INTERCEPTOR ERROR============');
    console.log(request);
    console.log(err);
    console.log('=========================================');
  }

}

/**
 * Provider for the interceptor
 */
export const ErrorInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: ErrorInterceptor,
  multi: true,
};
