import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BrandService {

  isStarbucksBranded(brand:string){
    if( ! brand )
      return false;
    return ['WPS', 'OTG'].includes(brand);
  }
}
