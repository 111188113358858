<p>Choice your experience</p>

<p>
  <a [routerLink]="['/coffee-with-love']" [queryParams]="query">Coffee with Love </a>
</p>

<p>
  <a [routerLink]="['/taste-personalization']" [queryParams]="query">Taste personalization</a>
</p>

<p>
  <a [routerLink]="['/customer-personalization']" [queryParams]="query">Customer personalization </a>
</p>
