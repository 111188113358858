<ng-container *ngIf="store">
  <div class="color-scheme-1">
    <ng-container *ngTemplateOutlet="currentChoice; context:{store, selection:store.currentSelection$ | async}"></ng-container>
    <ng-container *ngTemplateOutlet="loadedMenu; context:{store, selection:store.currentSelection$ | async}"></ng-container>
  </div>
</ng-container>

<ng-template #currentChoice let-store="store" let-selection="selection">
  <div class="d-flex flex-column justify-content-center align-items-center color-scheme-1 position-sticky sticky-top">
        <div>
          Customize your
        </div>
        <div class="text-medium text-center">
          {{selection?.family?.name}}
        </div>
  </div>
</ng-template>

<ng-template #loadedMenu let-store="store" let-selection="selection">
  <ng-container *ngTemplateOutlet="cupSizeMenu; context:{store,selection}"></ng-container>
</ng-template>

<ng-template #cupSizeMenu let-store="store" let-selection="selection">
  <div class="d-flex justify-content-center align-items-center w-100 mt-4" *ngIf="selection">
    <div *ngFor="let option of selection.family.cupsizes" (click)="select(option.code)" [class.disabled]="option.available === false"
         class="d-flex flex-column justify-content-center align-items-center">
      <div class="d-flex justify-content-center align-items-center w-50">
        <img [src]="option.code | frankeIcon : store.brand : 'cupSize'" [alt]="option.label" class="cupsize-img"
             [title]="option.code" [alt]="option.code">
      </div>
      <div class=" choice-label m0">{{option.code}}</div>
    </div>
  </div>
</ng-template>

<!-- <pre class="text-light">
  {{store.currentMenu$ | async | json}}
</pre>

<pre class="text-light">
  {{store.currentChoice$ | async | json}}
</pre> -->
