import { Injectable } from '@angular/core';
import { TouchlessRefundStatus } from '../enums/touchless-refund-status.enum';
import { TouchlessStatus } from '../enums/touchless-status.enum';

@Injectable({
  providedIn: 'root'
})
export class MachineStatusService {

  successPage: string;
  errorPage: string;

  constructor() { }

  statusMap = new Map([
    [TouchlessStatus.SUCCESS, { message: "Pickup the drink, is ready!", successful: true }],
      [TouchlessStatus.PAYMENT_ACCEPTED, { message: "Payment received.", successful: null }],
      [TouchlessStatus.PAYMENT_IN_PROGRESS, { message: "Payment in progress...", successful: null }],
    [TouchlessStatus.DISPENSING, { message: "Drink being dispensed...", successful: null }],

    [TouchlessStatus.BUSY, { message: "The coffee machine is busy.", successful: false }],
    [TouchlessStatus.FAILED, { message: "Failed. Something went wrong.", successful: false }],
    [TouchlessStatus.FAILED_TIMEOUT, { message: "Time out, no choice was made.", successful: false }],
    [TouchlessStatus.CANCELED, { message: "Your selection has been canceled.", successful: false }],
    [TouchlessStatus.MACHINE_OFFLINE, { message: "The coffee machine is switched off.", successful: false }],
    [TouchlessStatus.WRONG_REQUEST, { message: "Wrong request.", successful: false }],
    [TouchlessStatus.UNKNOWN_ERROR, { message: "Unknown error.", successful: false }],
    [TouchlessStatus.COMMUNICATION_ERROR, { message: "Communication error.", successful: false }],
    [TouchlessStatus.INTERNAL_ERROR, { message: "Internal error.", successful: false }],
      [TouchlessStatus.PAYMENT_ERROR, { message: "Payment error.", successful: false }],
      [TouchlessStatus.HMAC_KEY_NOT_VALID, { message: "HMAC KEY not valid.", successful: false }],
      [TouchlessStatus.ABORTED_TRANSACTION, { message: "Aborted transaction.", successful: false }],
  ]);

  refundStatusMap = new Map([
    [TouchlessRefundStatus.REFUND_SUCCESS, { message: "Transaction failed, your payment has been refunded to you.", successful: false }],
    [TouchlessRefundStatus.REFUND_IN_PROGRESS, { message: "Transaction failed, the refund of the payment is in progress.", successful: false }],
    [TouchlessRefundStatus.REFUND_FAILED, { message: "Transaction failed, the refund of the payment is failed.", successful: false }]
  ]);

  getMessageFromRefundStatus(refund_status: number) {
    let message = this.refundStatusMap.get(refund_status as TouchlessRefundStatus);
    if( ! message ) {
      message = { message: `Unknown error on refound (${refund_status})`, successful: false };
      console.log(`NO MAPPING FOR refund_status:${refund_status}`);
    } else {
      console.log(`MAPPING FOR refund_status:${refund_status} --> ${message.message}`,message);
    }
    return message;
  }

  getMessageFromStatus(transaction_status: number) {
    let message = this.statusMap.get(transaction_status as TouchlessStatus);
    if( ! message ) {
      message = { message: `Unknown error (${transaction_status})`, successful: false };
      console.log(`NO MAPPING FOR transaction_status:${transaction_status}`);
    } else {
      console.log(`MAPPING FOR transaction_status:${transaction_status} --> ${message.message}`,message);
    }
    return message;
  }
}
