<ng-container *ngIf="store">
  <div class="color-scheme-1">
    <ng-container *ngTemplateOutlet="currentChoice"></ng-container>
    <ng-container *ngTemplateOutlet="loadedMenu; context:{store, selection:store.currentSelection$ | async}"></ng-container>
  </div>
</ng-container>

<ng-template #currentChoice>
  <div class="d-flex flex-column justify-content-center align-items-center color-scheme-1 position-sticky sticky-top">
    <div>
      Customize your beverage
    </div>
  </div>
</ng-template>

<ng-template #loadedMenu let-store="store" let-selection="selection">
  <ng-container>
    <ng-container *ngTemplateOutlet="recapMenu; context:{store,selection}"></ng-container>
    <ng-container *ngTemplateOutlet="actionButtons; context:{selection}"></ng-container>
  </ng-container>
</ng-template>

<ng-template #recapMenu let-store="store" let-selection="selection">
  <div class="d-flex flex-column align-items-start justify-content-start no-gutter recap-menu mt-4">
    <ng-container *ngFor="let filter of selection.filters">
      <ng-container *ngTemplateOutlet="optionsMenu; context:{store,filter,selection}"></ng-container>
    </ng-container>
  </div>
</ng-template>

<ng-template #optionsMenu let-store="store" let-filter="filter" let-selection="selection">
  <ng-container *ngIf="!(! brandService.isStarbucksBranded(store.brand) && filter.code==='temperatureProfile')">
    <div class="d-flex align-items-center justify-content-start no-gutter">
      <img [src]="filter.code | frankeIcon : store.brand : 'buttons'" [alt]="filter.code | frankeLabel : store.brand" class="recap-menu-icon mr-3">
      <div class="recap-menu-label">{{filter.code | frankeLabel : store.brand}}</div>
    </div>
    <app-product-customizer [store]="store" [filters]="filter" [selection]="selection.filter"></app-product-customizer>
  </ng-container>
</ng-template>

<ng-template #actionButtons let-selection="selection" let-product="selection.product">
  <div class="pre-footer-spacer"></div>
  <div class="d-flex align-items-center justify-content-center fixed-bottom footer color-scheme-1 inverted">
    <img [src]="selection?.family?.picture" class="img-150 ml-2 mt-2"/>
    <div class="p-3 flex-grow-1">
      <div class="text-small text-left text-uppercase" *ngIf="selection?.cupSize && brandService.isStarbucksBranded(store.brand)">
        {{selection?.cupSize?.code | frankeLabel : store.brand}}
      </div>
      <div class="text-normal text-capitalize text-left">
        {{selection?.family?.name}}
      </div>
    </div>
    <button type="button" class="flex-fill btn btn-dark btn-primary text-capitalize" style="margin-right: 1em;"
            [disabled]="!product" (click)="brew.emit()">Make My Drink</button>
  </div>
</ng-template>

<!-- <pre class="text-light">
  {{store.currentMenu$ | async | json}}
</pre>

<pre class="text-light">
  {{store.currentChoice$ | async | json}}
</pre> -->
