import { AfterViewInit, Component, ElementRef, Input, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { IMachineStore, Menu } from '../../../models/machine.model';

@Component({
  selector: 'app-product-customizer',
  templateUrl: './product-customizer.component.html',
  styleUrls: ['./product-customizer.component.scss']
})
export class ProductCustomizerComponent implements AfterViewInit {

  @Input()
  store: IMachineStore;

  @Input()
  selection: any;

  @Input()
  filters: any;

  @ViewChild('panel') panel: ElementRef;

  @ViewChildren('optbutton') optButtons : QueryList<ElementRef>;

  calculate(s:any){
    let l = s;
    for (const o of this.optButtons) {
      if( o.nativeElement.classList.contains('selected') ){
        return l;
      }
      l += o.nativeElement.offsetWidth;
    }
    return l;
  }

  ngAfterViewInit(): void {
    if( this.panel )
      this.panel.nativeElement.scrollLeft+=this.calculate(0);
  }

  select(code:string,value:string){
    this.store.selectProductFeature(code,value);
  }

}
