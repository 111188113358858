import { CoffeMenuNames } from './../../../stores/machine-franke-store.service';
import { Component, EventEmitter, NgZone, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { BrandService } from '../../../services/brand.service';
import { DialogsService } from '../../../services/dialogs.service';
import { MachineStatusService } from '../../../services/machine-status.service';
import { MachineFactoryStoreService } from '../../../stores/machine-factory-store.service';
import { AbstractBeverageSelectionComponent } from '../../abstract-beverage-selection.component';

@Component({
  selector: 'app-vendon-product-recap',
  templateUrl: './product-recap.component.html',
  styleUrls: ['./product-recap.component.scss'],
})
export class VendonProductRecapComponent extends AbstractBeverageSelectionComponent implements OnInit
{
  activeModal: any;

  @Output()
  brew = new EventEmitter();

  constructor(
    public brandService: BrandService,
    router: Router,
    factory: MachineFactoryStoreService,
    // private spinnerService: SpinnerVisibilityService,
    // private modalService: NgbModal,
    private swalService: DialogsService,
    private machineStatusService: MachineStatusService,
    //checkoutStore: AdyenCheckoutStore,
    private zone: NgZone
    ) {
      super(router,factory);
  }

  ngOnInit(): void {
    super.init();
  }

  cancel() {
    this.store.back();
  }

}
