import { MachineVendor } from './../enums/machine-vendor.enum';
import { ProductSelectionStoreService } from './product-selection-store.service';
import { HttpCommandService } from './../services/http-command.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { IMachineStore } from '../models/machine.model';
import { firstValueFrom } from 'rxjs';
import { MachineFrankeStoreService } from './machine-franke-store.service';
import { MachineVendonStoreService } from './machine-vendon-store.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogsService } from '../services/dialogs.service';

@Injectable({
  providedIn: 'root'
})
export class MachineFactoryStoreService {

  private token:string;

  private readonly currentStore = new BehaviorSubject<IMachineStore|null>(null);
  public readonly currentStore$ = this.currentStore.asObservable();

  private readonly loading = new BehaviorSubject<boolean>(true);
  public readonly loading$ = this.loading.asObservable();

  constructor(
    private productService: ProductSelectionStoreService,
    private commandsService: HttpCommandService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: DialogsService
  ) {
      this.token = null;
      this.route.queryParams.subscribe( params => {
        const token = params.t;
        if( token && token.length > 0 && token !== this.token ){
          this.token = token;
          this.init(token);
        }
      });
  }

  public async init(token: string): Promise<void> {
    this.productService.token = token;
    this.loading.next(true);
    const call = this.commandsService.getMenu(token);
    try {
      console.log(token);
      const response = await firstValueFrom(call) as any;
      console.log(response);
      const machine = {
        machine_id: response.machine_id,
        vendor: response.vendor,
        brand: response.brand,
        data: response.data,
      };
      const store = machine.vendor === MachineVendor.Franke ?
      MachineFrankeStoreService.getInstance( this.productService, this.commandsService, machine ) :
      MachineVendonStoreService.getInstance( this.productService, this.commandsService, machine );
      this.currentStore.next( store );
    } catch (err) {
      console.error(`Unable to load menu`, err);
      this.dialog.showError('Missing or invalid QR code','Please rescan the QR code on the coffee machine.')
      this.router.navigate(['scanner']);
      return null;
    } finally{
      this.loading.next(false);
    }
  }
}
