import { Observable } from "rxjs";
import { AbstractPollStatusStoreService } from "../stores/poll-status/abstract-poll-status-store.service";

export class Machine {
  machine_id: string;
  vendor: number;
  brand?: string;
  data: any[];
}

export class FilterSelection {
  beverageFamily?: string;
  blendType?: string;
  cupSize?: string;
  extraShotEspresso?: string;
  extraShotFlavor?: string;
  flavorType?: string;
  milkType?: string;
  temperatureProfile?: string;
}

export class MenuItem {
  type: string;
  code: string;
  label: string;
  picture: string;

  price?:number;
  currency?:string;
  position?: number;

  available?: boolean;

  submenu?: Menu;
}

export class Menu{
  back?: string;
  code: string;
  options: MenuItem[];
}

export interface IMachineStore {
  machine_id: string;
  vendor: number; // 0 is vendon
  brand?: string;

  families: any[];
  products: any[];

  pollStore: AbstractPollStatusStoreService;

  currentSelection$: Observable<any>;

  loading$: Observable<boolean>;
  error$: Observable<any>;

  getCurrentSelection():any;

  selectFamily(code:string):void;
  selectCupsize(code:string):void;
  selectProduct(code:string):void;
  selectProductByCode(code:string):void;
  selectProductFeature(code:string,value:string):void;

  back():void;

  startErogation():void;
  // getCurrentChoice():any;
  // select(option: any):void;
  // gotoMenu(back: any):void;
  // startPayment():void;
  startPolling():void;
  stopPolling():void;
}
