import { environment } from 'src/environments/environment';
import { Component, OnInit } from '@angular/core';
import { BrandService } from '../../services/brand.service';
import { Router } from '@angular/router';
import { MachineFactoryStoreService } from '../../stores/machine-factory-store.service';
import { AbstractBeverageSelectionComponent } from '../abstract-beverage-selection.component';

@Component({
  selector: 'app-beverage-menu',
  templateUrl: './beverage-menu.component.html',
  styleUrls: ['./beverage-menu.component.scss']
})
export class BeverageMenuComponent extends AbstractBeverageSelectionComponent implements OnInit {

  public families_names_to_hide: string[];

  constructor(
    public brandService: BrandService,
    router: Router,
    factory: MachineFactoryStoreService,
  ) {
    super(router,factory);
    this.families_names_to_hide = environment.families_names_to_hide
  }

  ngOnInit(): void {
    console.log ('init BeverageMenuComponent');
    super.init();
  }
}
