import { Injectable } from '@angular/core';
import { TouchlessStatus } from '../../enums/touchless-status.enum';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export abstract class AbstractPollStatusStoreService {

  static numberOfIstances : number = 0;

  product: any;

  timer:any;

  pollIteration: number = 0;
  POLL_FREQUENCY: number = 5000;
  MAX_POLL_ITERATIONS: number = 40;

  busy = false;

  public transaction_id: any;

  protected readonly change = new BehaviorSubject<any>(true);
  public readonly change$ = this.change.asObservable();

  protected readonly result = new BehaviorSubject<any>(true);
  public readonly result$ = this.result.asObservable();

  constructor() {
    this.reset();
  }

  reset() {
    this.pollIteration = 0;
    this.POLL_FREQUENCY = 5000;
    this.MAX_POLL_ITERATIONS = 40;
  }

  public cancel() {
    this.stop();
    this.reset();
  }

  public abstract poll() : void;

  public check(result: any, product: any) {
    this.pollIteration = 0;
    this.product = product;
    const data = result.data;

    if( ! this.transaction_id ){
      console.log(`ATTENZIONE transaction_id=${this.transaction_id}`);
    }
    this.transaction_id = data?.transaction_id ?? -1;

    if ( this.transaction_id < 0 ) {
      this.result.next({ transaction_status: data.transaction_status, goBack: false, product });
      return;
    }

    console.log(`setting polling timer to ${this.POLL_FREQUENCY }ms`);
    this.timer = setInterval( () => this.tick(), this.POLL_FREQUENCY );
  }

  async tick(){
    console.log(`Polling tick`);
    if( this.busy === true ){
      console.log(`Polling timer catch but previous response pending`)
      return;
    } else if (this.pollIteration >= this.MAX_POLL_ITERATIONS) {
      this.stop();
      this.result.next({ error: true, message: 'Cancelled, no response' });
    } else {
      console.log(`Poll for status`);
      this.poll();
    }
    this.pollIteration++;
  }

  processResult(response: any) {
    this.busy = false;
    const data = response.data;
    if ( data.transaction_status === TouchlessStatus.DISPENSING
      || data.transaction_status === TouchlessStatus.PAYMENT_IN_PROGRESS
      || data.transaction_status === TouchlessStatus.PAYMENT_ACCEPTED
      ) {
      this.change.next({...data});
      return;
    }
    this.stop();
    this.result.next({ transaction_status: data.transaction_status, goBack: true,  product: this.product });
  }

  processError(err: any){
    console.error(err);
    this.busy = false;
    if (this.pollIteration >= this.MAX_POLL_ITERATIONS) {
      this.stop();
      this.result.next({ error: true, message: 'Cancelled, no response' });
      return;
    }
  }

  stop(){
    if (this.timer) {
      console.log(`Stopping polling`);
      clearInterval(this.timer);
      console.log(`Stopped polling`);
    }
    this.busy = false;
  }
}
