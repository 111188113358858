<div class="d-flex align-items-center justify-content-start no-gutter opt-buttons-container pl-4 pr-4 pb-4" #panel>
  <ng-container *ngFor="let opt of filters.options;let last=last;let index=index">
    <div class="flex-fill d-flex align-items-center justify-content-start no-gutter p-1 opt-button-ext" #optbutton
         [class.selected]="opt.code==selection[filters.code]">
      <button (click)="select(filters.code,opt.code)"
            class="d-flex align-items-center justify-content-start no-gutter w-100 p-1 opt-button"
            [class.selected]="opt.code==selection[filters.code]"
            [class.disabled]="opt.available === false"
            [disabled]="opt.available === false">
          <img class="opt-icon" [src]="opt.code | frankeIcon : store.brand : filters.code" [alt]="opt.code | frankeLabel : store.brand">
          <div class="opt-label">{{opt.code | frankeLabel : store.brand}}</div>
        </button>
    </div>
    <div class="opt-button-spacer d-sm-none" *ngIf="last && index > 1"></div>
  </ng-container>
</div>
