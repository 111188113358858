import { Component, OnDestroy } from '@angular/core';

@Component({ template: '' })
export abstract class AbstractAppComponent implements OnDestroy{

  subscriptions:any[] = [];

  register(subscription:any){
    this.subscriptions.push(subscription);
  }

  clearSubscriptions(){
    this.subscriptions.forEach( s => {
      try{
        s?.unsubscribe();
      } catch( err ){
        console.log('FAILED UNSUBSCRIPTION')
      }
    } );
  }

  ngOnDestroy(): void {
    this.clearSubscriptions();
  }

}
