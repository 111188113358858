
import { Component, OnInit } from '@angular/core';
import { PovStoreService } from './modules/app-common/stores/pov-store.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  constructor(
    private pov: PovStoreService
) {}

  ngOnInit(): void {
    this.pov.init();
    const documentHeight = () => {
      const doc = document.documentElement
      doc.style.setProperty('--doc-height', `${window.innerHeight}px`)
     }
     window.addEventListener('resize', documentHeight)
     documentHeight()
  }
}


